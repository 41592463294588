import React, { useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Map from "../HomePage/Map";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ha4tqq9",
        "template_9oj0ori",
        form.current,
        "QhFjRxZiApMKrb-4Y"
      )
      .then(
        (result) => {
          alert("Message Sent, We will get back to you shortly");
          console.log("form :  ", result.text);
        },
        (error) => {
          console.log("error :  ".error.text);
        }
      );
  };

  return (
    <>
      <Header />
      <section className="py-5">
        <h1 className="theme_heading_after">Contact</h1>
        <div className="contact-wrapper">
          {/* Left contact page */}
          {/* <form id="contact-form" className="form-horizontal" ref={form} role="form"> */}
          <form id="contactForm" ref={form} onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="NAME"
                  name="name"
                  defaultValue=""
                  required=""
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name="email"
                  defaultValue=""
                  required=""
                />
              </div>
            </div>
            <textarea
              className="form-control"
              rows={10}
              placeholder="MESSAGE"
              name="message"
              required=""
              defaultValue={""}
            />
            <button
              className="btn btn-primary send-button theme_btn p-2"
              type="submit"
            >
              <div className="alt-send-button">
                <i className="fa fa-paper-plane" />
                <span className="send-text">SEND</span>
              </div>
            </button>
            {/* <button
              className="btn btn-primary send-button theme_btn p-2"
              id="submit"
              type="submit"
              value="SEND"
              onClick={()=>handleSubmit()}
            >
              <div className="alt-send-button">
                <i className="fa fa-paper-plane" />
                <span className="send-text">SEND</span>
              </div>
            </button> */}
          </form>

          {/* Left contact page */}
          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-map-marker fa-2x"></i>
                <span className="contact-text place">MaryLand, USA.</span>
              </li>
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-phone fa-2x"></i>
                <span className="contact-text phone">
                  <a href="tel:+1 (443)-972-0500" title="Give me a call">
                    +1 (443)-972-0500
                  </a>
                </span>
              </li>
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-phone fa-2x"></i>
                <span className="contact-text phone">
                  <a href="tel:+1 (410)-564-9517">
                    <div />
                    <p>+1 (410)-564-9517 (Sunny. K)</p>
                  </a>
                </span>
              </li>
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-phone fa-2x"></i>
                <span className="contact-text phone">
                  <a href="tel:+1 (443)-830-7513">
                    {/* <i className="fa fa-phone" /> */}
                    <p>+1 (443)-830-7513 (kareem legsai)</p>
                  </a>
                </span>
              </li>

              {/* <div style={{marginLeft:'12%',marginBottom:20,marginTop:-5}}>
              <span className="contact-text phone" >
                  <a href="tel: +1 (443)-972-0500" title="Give me a call">
                  +1 (443)-972-0500
                  </a>
                </span>
                </div> */}
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-envelope fa-2x"></i>
                <span className="contact-text gmail">
                  <a
                    href="mailto:info@amglobalnetworksolutions.com"
                    title="Send me an email"
                  >
                    info@amglobalnetworksolutions.com
                  </a>
                </span>
              </li>
              <li className="list-item d-flex flex-row align-items-center">
                <i className="fa fa-envelope fa-2x"></i>
                <span className="contact-text gmail">
                  <a
                    href="legsai@amglobalnetworksolutions.com"
                    title="Send me an email"
                  >
                    legsai@amglobalnetworksolutions.com
                  </a>
                </span>
              </li>
            </ul>
            <hr />
            <ul className="social-media-list">
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <i className="fa fa-github" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/amglobalnetworksolutions/"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
            </ul>
            <hr />
            <div className="copyright">© ALL OF THE RIGHTS RESERVED</div>
          </div>
        </div>
      </section>
      <Map />
      <Footer />
    </>
  );
};

export default Contact;
