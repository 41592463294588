import React from "react";
import banner from "../../../assets/imgs/banner-1.jpg";
// import AmaLogistics from "./../../../assets/imgs/banner-video.mp4"

const BannerSection = () => {
  return (
    <>
      <div className="position-relative">
        <img src={banner} className="w-banner-1 w-100" />
        <div className="position-absolute top-50 ms-5 text-white z-index bg-colored">
          <h1 className="theme_main_heading">At Ama Global Networks,</h1>
          <h1 className="theme_main_heading">
            <em>every call matter</em>
          </h1>
          <p className="theme_para mb-5">
            We specialize in customized call center and answering services
          </p>
          <a
            href="/contact"
            className="btn_banner text-decoration-none rounded"
          >
            SEE HOW WE CAN HELP
          </a>
        </div>
      </div>
    </>
  );
};

export default BannerSection;
