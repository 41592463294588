import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import BannerSection from './BannerSection'
import HomeSection1 from './HomeSection1'
import HomeSection2 from './HomeSection2'
import HomeSection3 from './HomeSection3'
import Map from './Map'
import HomeSection4 from './HomeSection4'
import HomeSection5 from './HomeSection5'
import HomeSection6 from './HomeSection6'
import HomeSection7 from './HomeSection7'
import HomeSection8 from './HomeSection8'

const HomePage = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <HomeSection1 />
      <HomeSection3 />
      <HomeSection2 />
      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection7 />
      <HomeSection8 />
      {/* <Map /> */}
      <Footer />
    </>
  )
}

export default HomePage
