import React, { useEffect } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import serviceImg from "../../../assets/imgs/service-detail1.jpeg";
import inBound from "../../../assets/imgs/inbound.jpg";
import { useParams, useLocation } from "react-router-dom";
import ServicesOffered from "../../../components/ServicesOffered";
import { HOME_SCREEN_SERVICE_LIST } from "../../../common/Constant";
// import liveCallimg from "../../../assets/imgs/inbound-1.jpg";
// import serviceTwo from "../../../assets/imgs/service-2.jpeg";
// import serviceThree from "../../../assets/imgs/service-3.jpeg";
// import serviceFour from "../../../assets/imgs/service-4.jpeg";
// import serviceFive from "../../../assets/imgs/service-5.jpeg";
// import serviceSix from "../../../assets/imgs/service-6.jpeg";
// import serviceSeven from "../../../assets/imgs/service-7.jpeg";
// import serviceEight from "../../../assets/imgs/service-8.jpeg";
// import serviceNine from "../../../assets/imgs/service-9.jpeg";

const ServiceDetails = () => {
  const { id } = useParams();
  const [pageData, setPageData] = React.useState({});

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    switch (id) {
      case `live-answering-services`:
        setPageData({
          title: "InBound service",
          textOneHeading: `We’re On Duty and On Message – 24/7`,
          textOne: `Business owners know that having a live operator answer phone
          calls any time of the day, every day of the year – 24/7 – leaves a
          lasting, positive impression on customers.
          Am Global Network Solutions, operators are highly trained,
          experienced individuals who realize the importance of the service
          they provide. When a customer calls your number, they’ll be
          greeted by a happy, alert person who can answer questions,
          relay messages and make appointments.
          At Am Global Network Solutions, our operators never write their
          messages on paper, every piece of information they receive is
          input into customized software – it’s safe, secure and never gets
          lost.
          We also record every phone call that comes in to our centre,
          which serves as an excellent record if any questions arise about
          a message or a customer’s call experience.
          As a client, you’ll have complete access to these recordings.
          Listening to calls, and questions from customers, are an excellent
          way to better your product or business.
          Contact Am Global Network Solutions today and have our
          operators standing by 24/7 for your business – +1 (410)-564-9517`,
          // img: serviceTwo,
        });
        break;
      case `after-hours-services`:
        setPageData({
          title: `After Hours/Holiday Services`,
          textOneHeading: `Open for Business – Even When You’re Closed`,
          textOne: `Having a live operator answer your phones when your business
          is closed, or on holidays, gives your customers an impression of
          professionalism and commitment. You’ll also be able to respond
          in case of an emergency.
          Our operators work around the clock at our call centre, so you
          never miss an opportunity to answer a customer’s question or
          have them make an appointment.
          The phones ring even when your doors are closed, make sure
          they’re answered by a qualified Am Global Network Solutions
          operator. Call us at 443-972-0500`,
          // img: serviceTwo,
        });
        break;
      case `alarm-monitor-&-response`:
        setPageData({
          title: "Alarm Monitor and Response",
          textOneHeading: "Let Us Handle Your Emergencies",
          textOne: `Am Global Network Solutions never closes, which makes us the
          perfect service to handle any alarm or monitor alert for your
          business.
          There are several companies that use Am Global Network
          Solutions to monitor their technical and security alarms, our
          operators know exactly what to do when an emergency arises.
          We’ll call the technician so your critical equipment is serviced
          quickly and kept functioning – all you have to do is rest easy.
          We’re ready to help you, call Am Global Network Solutions today
          at 443-972-0500`,
          // img: serviceTwo,
        });
        break;
      case `appointments`:
        setPageData({
          title: "Appointments",
          textOneHeading: "Maximize Your Time – Never Miss an Appointment",
          textOne: `Am Global Network Solution's appointment scheduling service
          includes creating, updating and maintaining one or several
          calendars for your business. Our internet-based appointment
          scheduling takes the responsibility out of your hands and allows
          you to focus on more pressing issues.
          In addition to scheduling meetings and presentations for you and
          your team, Am Global Network Solutions schedules shared
          resources in your office like conference rooms, equipment and
          satellite offices.
          Appointment scheduling may seem like a simple task, but if it’s
          mishandled the results directly affect the overall productivity and
          profitability of a company.
          Our agents are available 24 hours a day, 7 days a week so your
          calendars contain the latest information, which means you, and
          your sales team, never miss a meeting.
          Call Am Global Network Solutions today at 443-972-0500, and
          have us keep time for you!`,
          img: serviceImg,
        });
        break;
      case `client-satisfaction-survey`:
        setPageData({
          title: "Update Business Website",
          textOneHeading: "Keep Your Inbox Empty, And Your Customers Happy",
          textOne: `Communication is vital to the health of any business, and our representatives integrate seamlessly with your methods. We’re experts at answering emails sent to your customer service, help desk or any other account.

          Working with our clients, Am Global Network compiles an FAQ and database of information so every email we answer is handled correctly. Whether it’s a boilerplate response or a customized message, it’s no problem for our employees.',
          textTwoHeading: 'Email Handling and Response Services',
          textTwo: 'Get your emails answered promptly, and accurately, without having to commit your employees’ time – or your own.

          Call us today at 800-638-2000.`,
          textThreeHeading: "",
          img: serviceImg,
        });
        break;
      case `customer-care`:
        setPageData({
          title: "Customer Care",
          textOneHeading: `Let Am Global Network Solutions Care for Your Customers`,
          textOne: `Building a business takes every minute of your day, and when
          you’re away from your duties as an owner, you’re losing money.
          That’s why outsourcing has become crucial to the success of
          small and mid-sized businesses.
          Am Global Network Solution's customer care services are the
          perfect answer for business owners who want their customers to
          receive professional care whenever they call.
          Our customer care personnel are fully educated on each of our
          clients’ products or services, so they can answer questions
          quickly, accurately and with confidence. In addition, they’re
          available 24 hours a day, 7 days a week so there’s never any
          downtime, or missed calls.
          Finally, per client instruction, our customer care experts will
          process customer requests. Every phone call is recorded for
          accuracy, and any messages, instructions or requests are
          documented via custom software.
          Let Am Global Network Solutions answer your phone calls, so
          you can grow your business. Call us today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `data-entry`:
        setPageData({
          title: "Data Entry",
          textOneHeading: `Accurate Data Entry Handled by Seasoned Experts`,
          textOne: `Once you’ve gathered data, it must be input into a software
          program so it’s easier to analyze and interpret – but data entry is
          an exhausting and time-consuming chore.
          When you outsource your data entry to Am Global Network
          Solutions, you’re freeing yourself and your employees to pursue
          more business and keep customers happy.
          Our data entry experts have years of experience and are fluent in
          the most popular data entry and database software programs
          available.
          Save time and money, and get your valuable data input so you
          can take advantage of it – call Am Global Network Solutions
          today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `dealer-locator-services`:
        setPageData({
          title: "Dealer Locator Services",
          textOneHeading: `Customers Can’t Buy If They Can’t Find You!`,
          textOne: `Businesses with multiple locations can spend a great deal of time
          on the phone giving out directions to customers, instead of
          attending to more important matters.
          Am Global Network Solutions operators are qualified to give
          directions over the phone and assist your potential customers
          with locating a location closest to their home or work.
          This service can be added to one of our other services, or it can
          be a handled in a stand-alone format.
          Stop giving directions and start making sales, call Am Global
          Network Solutions at 443-972-0500 today and we’ll deliver your
          customers to you!`,
          img: serviceImg,
        });
        break;
      case `dispatch-services`:
        setPageData({
          title: "Manage Online Sales",
          textOneHeading: `We’re Experts at Taking Orders`,
          textOne: ``,
          img: serviceImg,
        });
        break;
      case `e-commerce`:
        setPageData({
          title: "E-Commerce",
          textOneHeading: `Let Us Mind The Store`,
          textOne: `The advantage of having a virtual store is that it never closes,
          inventory doesn’t run out, and the cash register continually rings.
          However, someone must keep the store running. Am Global
          Network Soulutions's virtual store management services cover
          the following areas:
          • Process orders
          • Track inventory
          • Calculate shipping costs and taxes
          • Keep detailed sales reports
          • Maintain databases to identify repeat customers
          • Manage promotional codes
          • Follow up customer service
          • Up selling and cross selling
          • Sales tracking and statistics compilation
          Let us run your virtual store and increase your profits. Contact
          Am Global Network Solutions today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `email-handling-and-response`:
        setPageData({
          title: "Email Handling and Response",
          textOneHeading: `Keep Your Inbox Empty, And Your Customers Happy`,
          textOne: `Communication is vital to the health of any business, and our
          representatives integrate seamlessly with your methods. We’re
          experts at answering emails sent to your customer service, help
          desk or any other account.
          Working with our clients, Am Global Network Solutions compiles
          an FAQ and database of information so every email we answer is
          handled correctly. Whether it’s a boilerplate response or a
          customized message, it’s no problem for our employees.`,
          img: serviceImg,
        });
        break;
      case `emergency-hotline`:
        setPageData({
          title: "Emergency Hotlines",
          textOneHeading: `We Know There’s Not a Second to Spare`,
          textOne: `When your customers call your emergency hotline, they expect to
          speak to a well-trained operator who answers their questions
          immediately. Voice mail systems can’t get this job done.
          Am Global Network Solutions operators are trained to handle
          calls from emergency hotlines in a calm, businesslike manner.
          They follow scripts to ensure that the correct response process is
          being followed.
          Your customers will hang up with their situation resolved and feel
          positive about their interaction with your company. They’ll know
          you care about them, and your service or product, enough to
          have a live operator answer your calls.`,
          img: serviceImg,
        });
        break;
      case `executive-messaging`:
        setPageData({
          title: "Executive Messaging",
          textOneHeading: `Service For C-Level Executives`,
          textOne: `The executive team of any business, no matter the size, has
          multiple responsibilities – but if they’re too busy to take important
          phone calls, then opportunities could slip past.
          Am Global Network Solution's executive messaging service
          ensures the phone is always answered by a live operator and the
          message is taken quickly and accurately.
          After the call, our operator is instructed to relay the message
          according to your preferences: by phone, email, fax or text.
          Our technology allows us to transfer the call directly to your
          phone, whether you’re in the office, en route to a sales call, or
          enjoying yourself on the golf course.
          Find out more about our services, call us today at 443-972-0500
          Our professionals answer the phones, your professionals take
          care of business. Everyone wins!`,
          img: serviceImg,
        });
        break;
      case `help-desk`:
        setPageData({
          title: "Help Desk",
          textOneHeading: `Help Is On Its Way!`,
          textOne: `Technical support represents a crucial service to your customers,
          and when they call with a problem, they want to hear a voice, not
          deal with a complicated phone tree.
          Am Global Network Soulutions's help desk services are designed
          to provide level one technical support and qualify calls that need
          to be answered directly by one of your technical experts.
          This strategy allows your technical team to function at a higher
          level, solving major problems and keeping your customers happy
          and more likely to return.
          Our range of support assistance covers a wide variety of areas –
          our agents have access to a deep, well-designed FAQ database
          that’s constructed with input from each client.
          Keep your technical experts busy and productive, and your
          customers happy, and hire Am Global Network Solutions.
          Get help today – call 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `internet-response`:
        setPageData({
          title: "Internet Response Available - Text/Chat/Email",
          textOneHeading: `Talking Tech To Your Customers`,
          textOne: `Today’s technology allows customers to interact with your
          business in a variety of ways, but they require a person to
          generate the answer – Am Global Network Solutions’s employees
          make sure your customers get the right answer, any time of the
          day or night.
          Depending upon the mode of communication you choose – email,
          text, live chat – we can supply knowledgeable, trained personnel
          to answer questions, alert your team in case of emergency, or
          advance a question or problem further up your communication
          ladder.
          However we respond, you’ll receive a copy of the communication
          so you can gather critical information. We’re able to add the
          information to a database that’s easily accessible.
          Am Global Network Solutions’s integrated communication model
          gives your customers immediate response, and a positive
          impression of your business and brand.
          We’re ready to talk tech for you, call us to start at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `legal`:
        setPageData({
          title: "Legal",
          textOneHeading: `Hire a Receptionist That Never Sleeps`,
          textOne: `Attorneys know that the law never sleeps, and an after-hours call
          from a potential or current client needs to be answered by a
          person, not an impersonal machine.
          A live receptionist leaves a lasting impression of professionalism
          and care that clients don’t forget.
          Am Global Network Solutions offers agents who can be instructed
          to answer phone calls in a variety of ways, from taking messages
          to elevating a call to a higher priority. However, you want calls
          handled, Am Global Network Solutions accommodates you.
          Contact Am Global Network Solutions today and have a live
          receptionist answering your calls tomorrow! Call 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `marketing-survey`:
        setPageData({
          title: "Marketing Surveys",
          textOneHeading: `Know Your Audience – The Key to Successful Marketing`,
          textOne: `Before a company successfully markets and sells its product or
          services, it’s vital they know what their audience wants. Am
          Global Network Solutions has more than 30 years of experience
          administering marketing surveys.
          Before we begin making calls, operators meet with the client to
          review the survey and familiarize themselves with the subject
          matter. Professional experience has taught Am Global Network
          Solutions that a survey’s success rests on questions being asked
          correctly, and information provided as needed.
          Collecting the data is a key role in any survey, and Am Global
          Network Solutions’s operators input audience answers directly
          into a customized software program. This process eliminates
          error, and allows immediate access to data that might influence
          how a product or service is marketed.
          Survey results can also be compiled and input into a database by
          Am Global Network Solutions, ask about this service when you
          call 443-972-0500 to inquire about our marketing survey services.`,
          img: serviceImg,
        });
        break;
      case `media-response`:
        setPageData({
          title: "Media Response",
          textOneHeading: `Make Every Advertising Dollar Count`,
          textOne: `Marketing and advertising are keys to creating buzz about your
          product or service, and they represent a sizeable investment.
          Television, radio, internet, newspaper or billboard,
          advertisements instruct interested people to call your phone
          number.
          That’s why you want a live person answering the phone when a
          customer responds to your marketing campaign. Am Global
          Network Solutions operators are fully prepared to answer phone
          calls about your marketing campaign – your customers will be
          treated professionally and have all their questions answered.
          When they hang up the phone, the impression they receive will
          be of a professional company that’s reliable and trustworthy –
          and they’ll be more likely to purchase your service or product.
          Media response is crucial to growing your business, don’t have
          your customers call and talk to a recording, have them speak to a
          professionally trained Am Global Network Solutions employee!
          Get the most out of your marketing dollar, call Am Global Network
          Solutions today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `medical-emergencies`:
        setPageData({
          title: "Medical Emergencies",
          textOneHeading: `Hire a Full Time Medical Receptionist`,
          textOne: `Doctors and medical personnel need to stay connected with their
          patients and support team – regardless of the day or time.
          Am Global Network Solutions agents who answer the phone are
          trained to log information accurately and completely. Our
          customized computer scripting gives you the option of receiving
          messages and emergencies by alpha page, digital beeper, email,
          or a direct phone call.
          In addition to handling phone calls and emergencies correctly,
          Am Global Network Solutions agents are also able to schedule
          appointments for you. Find out more about this service here.
          Doctors and medical personnel can call 443-972-0500 and
          inquire about our services.`,
          img: serviceImg,
        });
        break;
      case `medical-surveys`:
        setPageData({
          title: "Medical Surveys",
          textOneHeading: `Medical Survey Services Performed Accurately`,
          textOne: `For more than 30 years, Am Global Network Solutions has administered
          medical surveys for doctor’s offices, health insurance companies,
          hospitals and a variety of other health related concerns.
          We understand the demand for accuracy, which is why we train each
          operator about medical vocabulary and patient sensitivity. These
          measures increase the respondent’s understanding of each question
          and the validity of their response.
          Patient surveys and data collection are not a problem, we’ve worked
          with thousands of medical research facilities. We’re also adept at long-
          term studies requiring multiple surveys, patient follow up and data
          comparison.
          With Am Global Network Solutions you receive accurate medical survey
          responses and a team that’s familiar with the medical field. The data you
          receive is compiled accurately and presented in easy to analyze
          software programs so you can quickly determine the results of your
          survey.
          Contact Am Global Network Solutions today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `doctor-billing`:
        setPageData({
          title: "Manage Online Sales",
          textOneHeading: `We’re Experts at Taking Orders`,
          textOne: ``,
          img: serviceImg,
        });
        break;
      case `medical-tas`:
        setPageData({
          title: "Medical TAS",
          textOneHeading: `We Provide a Pipeline of Information from Patient to Doctor`,
          textOne: `When a patient calls your practice, they want to speak with a
          knowledgeable person and be assured their message is relayed
          correctly and in a totally confidential manner.
          Our operators are trained to handle any kind of medical-related
          phone call, and know how to prioritize messages – and when to
          immediately upgrade a call to emergency status.
          When a Am Global Network Solutions operator receives a phone
          call, they take down the information, input it into a custom
          software package, and generate a message. You’ll receive the
          information via phone call, page, email or fax – the choice is
          yours.
          Messages and communication are vital keys to the success of
          your practice, call us today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `order-taking`:
        setPageData({
          title: "Order Taking",
          textOneHeading: `We’re Experts at Taking Orders`,
          textOne: `Am Global Network Solutions building its business on order taking and
          handling customer inquiries, we know every phone call represents an
          opportunity for your business to grow.
          Our order taking services are designed to handle high call volume and
          diverse product catalogs, without sacrificing accuracy and efficiency.
          Every order is taken quickly and correctly.
          Before they pick up a phone, our agents are fully trained and familiarized
          with your branding and messaging – their confidence on the phone
          results in more sales for you.
          With your input, Am Global Network Solutions creates customized
          scripting to greatly reduce errors or misinformation. Each script is
          designed to conclude with a call to action that increases the possibility of
          a sale.
          We also provide full FAQ integration with our agents, they don’t have to
          guess at an answer from a customer, because they know it. If you want
          an expert taking your orders, and making sure your profits grow, contact
          Am Global Network Solutions today at 443-972-0500Accurate, efficient
          order taking means you won’t lose a sale, or profit.`,
          img: serviceImg,
        });
        break;
      case `plumbling-and-hvacg`:
        setPageData({
          title: "Plumbing and HVAC",
          textOneHeading: `Essential Services for Your Service`,
          textOne: `Competition in the essential services market is fierce, and
          offering 24-hour operator assisted service, and appointment
          scheduling, can make the difference between you and a
          competitor.
          Am Global Network Solution’s operators are trained to handle any
          type of phone call for emergencies or service scheduling – the
          perfect solution for plumbers and HVAC business owners.
          Your phones will be answered by a live operator, who can answer
          basic questions and facilitate emergency calls, so you know
          about them almost instantaneously.
          We also offer appointment making services, so you won’t miss a
          potential customer who calls after operating hours.
          Find out more about our services, call us today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `set-reservation`:
        setPageData({
          title: "Set Reservations/Confirmation",
          textOneHeading: `Let Am Global Network Solutions Save The Date or Save a Seat!`,
          textOne: `If your businesses sells tickets, hosts conferences, seminars,
          dinners and other ceremonies – tracking responses and setting
          reservations is a crucial part of the job.
          Am Global Network Solutions offers reservation setting services,
          so you can concentrate on the thousands of other event details –
          ensuring an enjoyable, productive time for the participants.
          In addition to tracking event responses, Am Global Network
          Solution’s reservation setting services are available to clients who
          use shared resources such as conference rooms. We manage
          and control these resources remotely, so you and your
          employees are free to conduct business.
          If you’re ready to concentrate on your event, and let someone
          else keep track of reservations, call us today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `survey-prequalification`:
        setPageData({
          title: "Survey Prequalification",
          textOneHeading: `Our Prequalification Services Save You Time`,
          textOne: `If your survey requires a specific audience, you don’t want to
          waste time contacting unsuitable participants. Am Global Network
          Solutions can conduct this service with a full staff of operators
          available 24 hours a day, 7 days a week.
          Our scripting services help us create a roster of questions
          designed to eliminate unlikely participants, and qualify people
          whose input is valid.
          Like all of our other services, our operators are trained before
          they make their first phone call, and all data collected is logged
          into a special software program for later review.
          Call us today at 443-972-0500`,
          img: serviceImg,
        });
        break;
      case `surveys`:
        setPageData({
          title: "Surveys",
          textOneHeading: `Crucial Data Collection Requires an Expert’s Touch`,
          textOne: `There are many marketing tools available to today’s business
          owner, but surveys have consistently proven to be the most
          accurate and the most cost effective.
          Am Global Network Solutions has extensive experience
          conducting surveys of all types – from government approved
          scripting for clinical research to simple customer satisfaction
          surveys. Our operators gather the information accurately and log
          it per client instructions.
          With Am Global Network Solutions, we make the phone calls, you
          benefit from accurate results input by our professional team.
          Quick service and turnaround means you can use the data
          quickly and effectively.
          When you’re ready to distribute your survey, and reap the
          benefits from accurate data, contact Am Global Network
          Solutions at 443-972-0500
          Accurate results translate into successful marketing and
          advertising campaigns!`,
          img: serviceImg,
        });
        break;
      case `tech-support`:
        setPageData({
          title: "Tech Support",
          textOneHeading: `A Friendly, Helpful Tech Support Solution`,
          textOne: `Technology driven businesses need their tech support desk open
          constantly to handle questions, emergencies, and supply support.
          Am Global Network Solution’s operators do all this, and provide a
          live person for your customers to talk to.
          If you have a database that determines when customers have
          access to your tech support, we’re able to coordinate with that.
          We also schedule appointments.
          Am Global Network Solution's options aren’t limited solely to
          voice communication, we’ll answer emails from your customers –
          we’ll follow your instructions and route each request to your
          specifications.
          Tech problems occur around the clock, we’ll make sure they get
          answered on time. Call 443-972-0500 to schedule an
          appointment.`,
          img: serviceImg,
        });
        break;
      case `towing`:
        setPageData({
          title: "Towing",
          textOneHeading: `Keep the Trucks Rolling, 24 Hours a Day`,
          textOne: `Successful towing services never close their doors – and always
          answer their phones.
          Am Global Network Solutions agents can be on duty around the
          clock, and they’ll save you the added expense of having to pay
          an overnight hourly rate to an employee.
          When a call comes in to your number, one of our agents
          answers, logs the information needed and dispatches the request
          immediately if necessary. Your tow truck operators are rolling as
          soon as the call is completed.
          Keep the bottom line safe, and your trucks operating day and
          night by calling Am Global Network Solutions at 443-972-0500
          Let Am Global Network Solutions up your bottom line by being
          available 24/7 to dispatch your towing emergencies.`,
          img: serviceImg,
        });
        break;
      case `manage-online-sales`:
        setPageData({
          title: "Manage Online Sales",
          textOneHeading: `We’re Experts at Taking Orders`,
          textOne: `Am Global Network built its business on order taking and handling customer inquiries, we know every phone call represents an opportunity for your business to grow.

                                                            Our order taking services are designed to handle high call volume and diverse product catalogs, without sacrificing accuracy and efficiency. Every order is taken quickly and correctly.

                                                            Before they pick up a phone, our agents are fully trained and familiarized with your branding and messaging – their confidence on the phone results in more sales for you.

                                                            With your input, Am Global Network creates customized scripting to greatly reduce errors or misinformation. Each script is designed to conclude with a call to action that increases the possibility of a sale.

                                                            We also provide full FAQ integration with our agents, they don’t have to guess at an answer from a customer, because they know it.

                                                            If you want an expert taking your orders, and making sure your profits grow, contact Am Global Network today at 800-638-2000.`,
          img: serviceImg,
        });
        break;
      case `mobile-app-development`:
        setPageData({
          title: "Mobile App development",
          textOneHeading: "Mobile App Consulting",
          textOne:
            "Without consultation, developing a mobile application in the right direction is a cumbersome task. Our fully-vetted mobile app developers endeavor a solid consultation from conceptualization to deployment to launch your mobile app to scale your business successfully.",
          textTwoHeading: "Mobile App Development",
          textTwo:
            "Completing 100+ fail-proof projects, we have the potential to develop mobile apps, native, cross-platform, and progressive web apps. With our mobile application development services, experience the best-in-class performance of your app.",
          textThreeHeading: "Mobile App Design",
          textThree:
            "We understand electrifying the look of mobile apps is crucial to making them super eye-catching and intuitive. Therefore, we have a team of inspiring UI/UX designers to attract customers and intrigue them to start a business partnership with you instantly.",
          img: serviceImg,
        });
        break;
      case `web-app-development`:
        setPageData({
          title: "Web App development",
          textOneHeading: "Custom Web Apps",
          textOne:
            "Digitalizing business to build your brand has become crucial to escalating sales. Our custom web app development services activate your business success by developing a solid web app that shows your business services in front of the online world.",
          textTwoHeading: "E-Commerce Website",
          textTwo:
            "Selling products or services online requires an e-commerce site. At PureLogics, we empower your online stores with the remarkable integration of shopping carts, product categorization, online payment processing, etc.",
          textThreeHeading: "Frontend Development",
          textThree:
            "We convert the aesthetic design into a tangible user interface by executing front-end development. HTML, CSS, JavaScript, Bootstrap, JQuery, React, Angular, Vue.js, and TypeScript are our identical front-end languages for front-end web development solutions.",
          img: serviceImg,
        });
        break;
      case `backend-development`:
        setPageData({
          title: "BackEnd development",
          img: serviceImg,
        });
        break;
      default:
        break;
    }
  }, [id]);
  return (
    <>
      <Header />
      <div className="p-5">
        <h1 className="theme_heading_after text-center mb-5">
          {pageData?.title}
        </h1>
        <div className="container">
          <img
            src={inBound}
            className="img-fluid"
            alt="Inbound Service"
            style={{ width: "100%", height: 350 }}
          />
          <div className="col-lg-12 col-md-12">
            <p className="lead">{pageData?.textOne}</p>
          </div>

          <ServicesOffered serviceData={HOME_SCREEN_SERVICE_LIST} />

          <div class="service-link">
            <a class="button" href="/service">
              View All Services
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServiceDetails;
