import React from "react";
import { Link } from "react-router-dom";

const ServicesOffered = ({ serviceData }) => {
  return (
    <div className="container mb-5">
      <div className="row">
        {serviceData &&
          serviceData.length > 0 &&
          serviceData.map((item, index) => {
            return (
              <div className="col-md-3">
                <Link
                  className="no-underline"
                  to={`/service-detail/${item.screenParam}`}
                >
                  <div className="card_border  p-3">
                    <img
                      src={item.img}
                      width={50}
                      height={50}
                      className="padding"
                    />
                    <h3 className="cardTxtColor">{item.title}</h3>
                    <p className="cardSubTxt">{item.description}</p>
                    <h4 className="learnMore">Learn More</h4>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ServicesOffered;
