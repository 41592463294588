import React from 'react'

const HomeSection2 = () => {
  return (
    <>
      <div class="container py-5" id="featured-3">
        <div className='w_50 mx-auto'>
          <h1 className='theme_heading_after'>Our Services are defined by quality and commitment.</h1>
          <p className=''>Rapid innovation is an essential feature of digital information technology. AM Global Network understands the importance of remaining current through frequent self-reinvention. We accomplish this by continually attracting the brightest minds in modern digital paradigms and platforms. By maintaining a roster of skilled technology professionals across every business function, AM Global Networks distinguishes itself by offering its client organizations a single touchpoint to address all of their enterprise technology needs.</p>
        </div>
      </div>
    </>
  )
}

export default HomeSection2
