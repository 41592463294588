import phoneImg from "../assets/imgs/phone.png";
import agentImg from "../assets/imgs/agent.png";
import groupImg from "../assets/imgs/group.png";
import settingImg from "../assets/imgs/setting.png";
import messageImg from "../assets/imgs/message.png";
import cartImg from "../assets/imgs/cart.png";

const HOME_SCREEN_SERVICE_LIST = [
  {
    id: 1,
    title: "Live Answering Services",
    description:
      "Have a LIVE operator taking messages for you and forwarding them by text or email in real time.",
    img: phoneImg,
    screenParam: "live-answering-services",
  },
  {
    id: 2,
    title: "AFTER HOURS/HOLIDAY SERVICES",
    description:
      "Am Global Network has customer service experts who are trained in handling customer care, help desk and tech support services.",
    img: agentImg,
    screenParam: "after-hours-services",
  },
  {
    id: 3,
    title: "ALARM MONITOR AND RESPONSE",
    description:
      "Our dispatchers will respond to any type of emergency service-related issue following YOUR instructions for contacting on-call staff.",
    img: agentImg,
    screenParam: "alarm-monitor-&-response",
  },
  {
    id: 4,
    title: "APPOINTMENTS",
    description:
      "Our operators are highly trained and have the experience and knowledge to separate your new, (or delete) existing customers, or emergency calls, from your unwanted calls.",
    img: phoneImg,
    screenParam: "appointments",
  },
  {
    id: 5,
    title: "CLIENT AND CUSTOMER EMPLOYEE SATISFACTION SURVEYS",
    description:
      "Have a LIVE operator taking messages for you and forwarding them by text or email in real time.",
    img: groupImg,
    screenParam: "client-satisfaction-survey",
  },
  {
    id: 6,
    title: "CUSTOMER CARE",
    description:
      "When you're running a T.V., Radio or mailing campaigns, Am Global Network can handle your call volume spikes, (and delete) take orders, (or delete) qualify callers for call forwarding to a representative and much more.",
    img: settingImg,
    screenParam: "customer-care",
  },
  {
    id: 7,
    title: "DATA ENTRY",
    description:
      "When you have messages or requests from your web forms, Am Global Network can respond to hem 24/7in real time.",
    img: messageImg,
    screenParam: "data-entry",
  },
  {
    id: 8,
    title: "DEALER LOCATOR SERVICES",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "dealer-locator-services",
  },
];

const SERVICE_SCREEN_SERVICE_LIST = [
  {
    id: 21,
    title: "MEDICAL SURVEYS",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "medical-surveys",
  },
  {
    id: 22,
    title: "DOCTOR BILLING",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "doctor-billing",
  },
  {
    id: 23,
    title: "MEDICAL TAS",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "medical-tas",
  },
  {
    id: 4,
    title: "APPOINTMENTS",
    description:
      "Our operators are highly trained and have the experience and knowledge to separate your new, (or delete) existing customers, or emergency calls, from your unwanted calls.",
    img: phoneImg,
    screenParam: "appointments",
  },
  {
    id: 12,
    title: "EMERGENCY HOTLINES",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "emergency-hotline",
  },
  {
    id: 1,
    title: "Live Answering Services",
    description:
      "Have a LIVE operator taking messages for you and forwarding them by text or email in real time.",
    img: phoneImg,
    screenParam: "live-answering-services",
  },
  {
    id: 2,
    title: "AFTER HOURS/HOLIDAY SERVICES",
    description:
      "Am Global Network has customer service experts who are trained in handling customer care, help desk and tech support services.",
    img: agentImg,
    screenParam: "after-hours-services",
  },
  {
    id: 3,
    title: "ALARM MONITOR AND RESPONSE",
    description:
      "Our dispatchers will respond to any type of emergency service-related issue following YOUR instructions for contacting on-call staff.",
    img: agentImg,
    screenParam: "alarm-monitor-&-response",
  },

  {
    id: 5,
    title: "CLIENT AND CUSTOMER EMPLOYEE SATISFACTION SURVEYS",
    description:
      "Have a LIVE operator taking messages for you and forwarding them by text or email in real time.",
    img: groupImg,
    screenParam: "client-satisfaction-survey",
  },
  {
    id: 6,
    title: "CUSTOMER CARE",
    description:
      "When you're running a T.V., Radio or mailing campaigns, Am Global Network can handle your call volume spikes, (and delete) take orders, (or delete) qualify callers for call forwarding to a representative and much more.",
    img: settingImg,
    screenParam: "customer-care",
  },
  {
    id: 7,
    title: "DATA ENTRY",
    description:
      "When you have messages or requests from your web forms, Am Global Network can respond to hem 24/7in real time.",
    img: messageImg,
    screenParam: "data-entry",
  },
  {
    id: 8,
    title: "DEALER LOCATOR SERVICES",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "dealer-locator-services",
  },
  {
    id: 9,
    title: "DISPATCH SERVICES",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "dispatch-services",
  },
  {
    id: 10,
    title: "E-COMMERCE",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "e-commerce",
  },
  {
    id: 11,
    title: "EMAIL HANDLING AND RESPONSE",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "email-handling-and-response",
  },
  {
    id: 13,
    title: "EXECUTIVE MESSAGING",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "executive-messaging",
  },
  {
    id: 14,
    title: "HELP DESK",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "help-desk",
  },
  {
    id: 16,
    title: "INTERNET RESPONSE AVAILABLE – TEXT/CHAT/EMAIL",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "internet-response",
  },
  {
    id: 17,
    title: "LEGAL",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "legal",
  },
  {
    id: 18,
    title: "MARKETING SURVEYS",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "marketing-survey",
  },
  {
    id: 19,
    title: "MEDIA RESPONSE",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "media-response",
  },
  {
    id: 20,
    title: "MEDICAL EMERGENCIES",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "medical-emergencies",
  },
  {
    id: 24,
    title: "ORDER TAKING",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "order-taking",
  },
  {
    id: 25,
    title: "PLUMBING AND HVACG",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "plumbling-and-hvacg",
  },
  {
    id: 26,
    title: "SET RESERVATIONS / CONFIRMATION",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "set-reservation",
  },
  {
    id: 27,
    title: "SURVEY PREQUALIFICATION",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "survey-prequalification",
  },
  {
    id: 28,
    title: "SURVEYS",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "surveys",
  },
  {
    id: 29,
    title: "TECH SUPPORT",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "tech-support",
  },
  {
    id: 30,
    title: "TOWING",
    description:
      "Our operators sre experienced in all aspects of catalog and website customer services.",
    img: cartImg,
    screenParam: "towing",
  },
];

export { HOME_SCREEN_SERVICE_LIST, SERVICE_SCREEN_SERVICE_LIST };
