import React from "react";
import { TiSocialPinterest } from "react-icons/ti";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaRss } from "react-icons/fa";
import logo from "../assets/imgs/ama-sol-logo.png";
import wbenc from "../assets/imgs/wbenc-logo.png";
import mbe from "../assets/imgs/mbe-logo.png";
import wbe from "../assets/imgs/wbe-logo.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
              <div className="footer-logo d-flex flex-column">
                <img src={logo} height={100} />
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <a
                    href="https://www.facebook.com/amglobalnetworksolutioninc?mibextid=LQQJ4d"
                    className="d-flex justify-content-center border-color rounded-circle me-3 p-2"
                  >
                    <FaFacebookF size={18} />
                  </a>
                  <a
                    href="#"
                    className="d-flex justify-content-center border-color rounded-circle me-3 p-2"
                  >
                    <FaTwitter size={18} />
                  </a>
                  <a
                    href="https://www.instagram.com/amglobalnetworksolutions?igshid=OGQ5ZDc2ODk2ZA=="
                    className="d-flex justify-content-center border-color rounded-circle me-3 p-2"
                  >
                    <TiSocialPinterest size={20} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@amglobalnetwork?_t=8iI6XD4Cavq&_r=1"
                    className="d-flex justify-content-center border-color rounded-circle me-3 p-2"
                  >
                    <FaLinkedinIn size={18} />
                  </a>
                  <a
                    href="#"
                    className="d-flex justify-content-center border-color rounded-circle me-5 p-2"
                  >
                    <FaRss size={18} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-address-info">
                <h3 className="mb-3">Contact Information</h3>
                <p className="m-0">Ellicott City, MD 21043</p>
                {/* <p>Glen Burnie, MD 21041</p> */}
              </div>
              <div className="footer-phone">
                {/* <p className="m-0">Toll Free:
                  <a href="#" className='text-decoration-none text-secondary'>800-638-2000</a>
                </p> */}

                <div style={{ marginTop: 20 }}>
                  <a
                    className="text-decoration-none text-secondary"
                    href="tel:+1 (718)-749-2984"
                  >
                    <p className="text-decoration-none text-secondary">
                      +1 (718)-749-2984
                    </p>
                  </a>
                </div>
                <div style={{}}>
                  <a
                    className="text-decoration-none text-secondary"
                    href="tel:+1 (410)-564-9517"
                  >
                    <p>+1 (410)-564-9517 (Sunny. K)</p>
                  </a>
                </div>
                <div style={{}}>
                  <a
                    className="text-decoration-none text-secondary"
                    href="tel:+1 (443)-830-7513"
                  >
                    <p>+1 (443)-830-7513 (kareem legsai)</p>
                  </a>
                </div>
                {/* <p className="m-0">Local:
                  <a href="#" className='text-decoration-none text-secondary'>+1 (800)-279-1745</a>
                </p> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="expolore">
                <h3>Explore Our Site</h3>
                <div className="d-flex justify-content-start">
                  <ul className="list-unstyled me-4">
                    <li className="my-3">Home</li>
                    <li className="my-3">About Us</li>
                    <li className="my-3">Services</li>
                    <li className="my-3">Regions</li>
                    <li className="my-3">Support</li>
                    <li className="my-3">Live Operators</li>
                  </ul>
                  <ul className="list-unstyled">
                    <li className="my-3">Blog</li>
                    <li className="my-3">Contact Us</li>
                    <li className="my-3">Employment</li>
                    <li className="my-3">Local Areas</li>
                    <li className="my-3">Privacy Policy</li>
                    <li className="my-3">Site Map</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <ul className="d-flex justify-content-center align-items-center list-unstyled gap-2">
                <li>
                  <img src={wbenc} />
                </li>
                <li>
                  <img src={mbe} />
                </li>
                <li>
                  <img src={wbe} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-copyright p-2">
        <div className="container">
          <ul className="d-flex justify-content-center align-items-center text-white list-unstyled m-0">
            <li className="me-2">Am Global Network Solutions © 2024</li>
            <li className="me-2">|</li>
            <li className="me-2">All Rights Reserved</li>
            <li className="me-2">|</li>
            <li className="me-2">
              Website Design & Marketing provided by CrocusZone Pvt Limited
            </li>
          </ul>
        </div>
      </div>
      {/* <footer className="footer-distributed">
        <div className="footer-left">
          <h3>
            <img src={logo} height="60" />
          </h3>
          <p className="footer-links">
            <a href="/" className="link-1">
              Home
            </a>
            <a href="/about">About</a>
            <a href="/service">Service</a>
            <a href="/contact">Contact</a>
          </p>
          <p className="footer-company-name">
            Am Global Network Solutions © 2024
          </p>
        </div>
        <div className="footer-center">
          <div>
            <i className="fa fa-map-marker" />
            <p>Ellicott City, MD 21043</p>
          </div>
          <div>
            <a href="tel:+1 (443)-972-0500">
              <i className="fa fa-phone" />
              <p>+1 (443)-972-0500</p>
            </a>
          </div>
          <div style={{ marginLeft: "15%", marginTop: -20 }}>
            <a href="tel:+1 (718)-749-2984">
              <p>+1 (718)-749-2984</p>
            </a>
          </div>
          <div style={{ marginLeft: "15%" }}>
            <a href="tel:+1 (410)-564-9517">
              <p>+1 (410)-564-9517 (Sunny. K)</p>
            </a>
          </div>
          <div style={{ marginLeft: "15%" }}>
            <a href="tel:+1 (443)-830-7513">
              <p>+1 (443)-830-7513 (kareem legsai)</p>
            </a>
          </div>
          <div>
            <i className="fa fa-envelope" />
            <p>
              <a href="mailto:info@amglobalnetworksolutions.com">
                info@amglobalnetworksolutions.com
              </a>
            </p>
          </div>
          <div>
            <i className="fa fa-envelope" />
            <p>
              <a href="legsai@amglobalnetworksolutions.com">
                legsai@amglobalnetworksolutions.com
              </a>
            </p>
          </div>
        </div>
        <div className="footer-right">
          <p className="footer-company-about">
            <span>About the company</span>
            AM Global Network has been providing a comprehensive range of
            solutions to our diverse customer base.
          </p>
          <div className="footer-icons">
            <a href="https://www.facebook.com/amglobalnetworksolutioninc?mibextid=LQQJ4d">
              <i className="fa fa-facebook" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
            <a href="https://www.linkedin.com/company/am-global-network-solutions/">
              <i className="fa fa-linkedin" />
            </a>
            <a href="https://www.instagram.com/amglobalnetworksolutions?igshid=OGQ5ZDc2ODk2ZA==">
              <i className="fa fa-instagram" />
            </a>
            <a href="https://www.tiktok.com/@amglobalnetwork?_t=8iI6XD4Cavq&_r=1">
              <i className="fa fa-tiktok" />
            </a>
            <a href="#">
              <i className="fa fa-github" />
            </a>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default Footer;
