import logo from "./logo.svg";
import "./App.css";
import "./assets/css/user.css";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ServicePage from "./pages/ServicePage";
import FaqPage from "./pages/FAQ";
import ServiceDetails from "./pages/ServicePage/ServiceDetails";
import SupportPage from "./pages/SupportPage";
import LiveOperatorPage from "./pages/LiveOperator";

function App() {
  useEffect(() => {
    document.title = "AMA Global Network Solutions";
  }, []);

  return (
    <div className="App">
      {/* <Header /> */}
      {/* <HomePage /> */}
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/service-detail/:id" element={<ServiceDetails />} />
            <Route path="/ServiceDetails" component={<ServiceDetails />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/live-operators" element={<LiveOperatorPage />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
