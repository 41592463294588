import React from "react";
import inBound from "../../../assets/imgs/inbound.jpg";
import outBound from "../../../assets/imgs/outbound.jpg";
import automated from "../../../assets/imgs/automated.jpg";
import ServicesOffered from "../../../components/ServicesOffered";
import { HOME_SCREEN_SERVICE_LIST } from "../../../common/Constant";

const HomeSection3 = () => {
  return (
    <div>
      <div className="container-fluid text-center">
        <ServicesOffered serviceData={HOME_SCREEN_SERVICE_LIST} />

        <div className="service-link">
          <a className="button text-decoration-none mb-4" href="/service">
            View All Services
          </a>
        </div>

        {/* <h1 className="theme_heading_before" style={{ color: "#061E64" }}>
          OUR SPECIALIZED SERVICES INCLUDES
        </h1> */}

        {/* <div class="row g-4 py-3 row-cols-1 row-cols-lg-3">
          <div class="feature col container_foto position-relative bg-dark">
            <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
              <a href="/contact" class="icon-link">
                View More
                Contact Us
              </a>
            </div>
            <article className="text-left">
              <h2>InBound</h2>
              <h4>
                Learn About inbond call center services that can save your
                company time and money
              </h4>
            </article>
            <img
              src={inBound}
              src="https://img-aws.ehowcdn.com/400x400/ds-img.studiod.com/Half_Dome_from_Glacier_Point0_1.jpg"
              alt=""
            />
          </div>
          <div class="feature col container_foto position-relative bg-dark">
            <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
              <a href="/contact" class="icon-link">
                View More
                Contact Us
              </a>
            </div>
            <article className="text-left">
              <h2>OutBound</h2>
              <h4>
                OutBound services can drive revenue for your organization
                without any overheated costs.
              </h4>
            </article>
            <img
              src={outBound}
              src="https://pbs.twimg.com/profile_images/781518570018648065/HcvZhTVn_400x400.jpg"
              alt=""
            />
          </div>
          <div class="feature col container_foto position-relative bg-dark">
            <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
              <a href="/contact" class="icon-link">
                View More
                Contact Us
              </a>
            </div>
            <article className="text-left">
              <h2>Automated</h2>
              <h4>
                From customer service emails, to CRM hosting, call centers can
                handle your all electronic needs.
              </h4>
            </article>
            <img
              src={automated}
              height={400}
              src="https://img-aws.ehowcdn.com/400x400/ds-img.studiod.com/Half_Dome_from_Glacier_Point0_1.jpg"
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomeSection3;
