import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesOffered from "../../components/ServicesOffered";
import { SERVICE_SCREEN_SERVICE_LIST } from "../../common/Constant";

const LiveOperatorPage = () => {
  return (
    <>
      <Header />
      <div className="pt-5">
        <h1 className="theme_heading_after">
          Have A Live Operator Answer Your Calls
        </h1>
        <div className="container mb-5">
          <p className="col-12 col-md-7 mx-auto">
            In today’s business world, people who stand out find success. Ama
            Global Network provides services – including live operators and
            fully staffed call centers – that help businesses differentiate
            themselves from the competition.
          </p>
          <p className="col-12 col-md-7 mx-auto">
            Whether it’s early afternoon or in the middle of the night, a live
            operator gives your clients a sense of ease because they’ll be
            interacting with a person, not a recording.
          </p>
          <p className="col-12 col-md-7 mx-auto">
            Our operators are fully trained before they’re allowed to answer
            phone calls, this ensures accuracy in message taking and a
            professional exchange with your clients.
          </p>
          <p className="col-12 col-md-7 mx-auto">
            Every conversation is recorded electronically and stored so you may
            review them whenever you wish. This backup system increases accuracy
            and provides a verbal record of every conversation.
          </p>
          <p className="col-12 col-md-7 mx-auto">
            Give your business the edge it needs to stand out from the
            competition, and call Ama Global Network today +1 (410)-564-9517.
          </p>
        </div>
        <ServicesOffered serviceData={SERVICE_SCREEN_SERVICE_LIST} />

        <div className="bg-light py-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 text-start">
                <h1 className="theme_heading_regular text-start">FAQ</h1>
                <p>
                  Don’t see the topic you’re looking for? Check out our blog or
                  contact our team. We’re here to help.
                </p>
                <a href="/faq" className="btn btn-primary theme_btn btn-lg">
                  Explore More
                </a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is an inbound call centre?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>
                          An inbound call center receives incoming calls from
                          customers. Support teams typically monitor inbound
                          centers since the calls tend to come from existing
                          customers with issues or questions. An outbound call
                          center, on the other hand, makes outgoing calls to
                          shoppers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        What does inbound calls mean?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>
                          Inbound calling occurs when customers or potential
                          customers initiate contact with a business or
                          organization. Inbound call center agents provide
                          reactive customer support, meaning when someone calls,
                          they react.
                        </p>
                        {/* <p>The Incoterms® Rules are protected by copyright owned by ICC.  Further information on the Incoterms® Rules may be obtained from the ICC website iccwbo.org.</p> */}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        How many types of inbound calls are there?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>There are two main types of inbound calls:</p>
                        <p>
                          These calls are usually initiated by potential
                          customers who have a question about a product or
                          service. Agents handling this type of call are
                          answering customer questions and trying to prompt a
                          sale.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LiveOperatorPage;
