import React from "react";
import rep from "../../../assets/imgs/rep.jpg";

const HomeSection5 = () => {
  return (
    <div className="home-section5-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="representative-content">
              <h2 className="mb-3">
                We call our telephone representatives (Ama Global Network).
              </h2>
              <span>
                <p>
                  Ama global Network are recruited and trained locally. Our
                  clients range from small business owners to Fortune 500
                  companies. They enjoy working with us because we are local,
                  flexible and we offer varied services that help them grow
                  their businesses – 24 hours a day! We encourage clients to
                  visit us and participate in training our Ama Global Network so
                  we can answer calls just as they would.
                </p>
                <p>
                  Our extensive experience since 2010, enables us to offer
                  valuable insight in finding solutions to meet our clients
                  needs — whether they be answering customer service calls or
                  responding to emails. Our outsourcing programs are many and
                  varied.
                </p>
                <p>
                  Find out how Ama Global Network can help you provide the very
                  best service to your customers.
                </p>
              </span>
              <button class="button text-decoration-none" href="/service">
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <img src={rep} className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection5;
