import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const FaqPage = () => {
  return (
    <>
      <Header />
      <div className="p-5">
        <h1 className="theme_heading_after">FAQ</h1>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                class="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Why should I hire you?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>We're committed to delivering exceptional service to our clients, and hiring me means welcoming someone who shares that dedication. My experience in customer service, coupled with my strong communication skills, enables me to handle a wide range of inquiries effectively. I'm passionate about providing top-notch assistance and ensuring customer satisfaction, which aligns perfectly with the values of our call center company. Moreover, my adaptability and quick learning will allow me to swiftly grasp and implement company-specific processes, contributing to our collective success</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      What is an Outbound Call?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Outbound calls occur when a business or organization initiates a call with a customer or client. For instance, a cable company might dial up a customer who is late on their monthly bill to request payment. Telemarketing is also a common type of outbound calling. These are examples of an outbound calls since the call center is taking the initiative to reach out to the consumer.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What is the Difference Between Inbound and Outbound Call Center Services?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>
                      The key difference between outbound and inbound call center services is who is initiating the call. An inbound call center handles calls made by consumers. In an outbound call center, agents are the ones dialing the consumers. Types of inbound call center services include traditional telephone answering services, appointment scheduling, customer service, order taking, technical support, and hotline services. Types of outbound call center services include telemarketing, surveys, and reminders.</p>
                      <p>There are plenty of call centers that are a hybrid, conducting both inbound and outbound call center services. However, many call center businesses choose to be an outbound or an inbound call center and focus on that specialty.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Why are Call Centers Important?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Call centers are important, because they are a link between people and businesses. Not only do call centers help people who call businesses by providing good customer experiences, but call centers also help companies accomplish their goals to be successful.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      What are the Benefits of Outsourcing Call Center Services?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Businesses that outsource call center services benefit in a number of ways. One of the most common reasons to outsource to a call center is to save money. It is very expensive to build an in-house call center and fill it with all the hardware, software, and employees required to run smoothly. By outsourcing to a call center partner, companies can keep their costs low and controlled while still accomplishing their goals.</p>
                      <p>Another benefit of outsourcing call center services is the amount of time it saves. Rather than taking all the time to plan, build, operate and manage an in-house call center, all a business has to do is explain to a third party call center what they want to accomplish and the call center takes care of the rest.</p>
                      <p>One more primary benefit of call center outsourcing is the ability to focus on core competencies. Every business has something they are good at, which is usually their reason for being a company in the first place. It is hard to focus on that primary business function when attention must go towards providing your own call center services. Instead, businesses outsource those tasks to a call center whose sole purpose it is to provide those services (that’s what they are good at) so they can focus on what they do best.</p>
                      <p>Outsourcing call center services also allows organizations to increase their availability to callers, provide better customer service, and keep their own employees focused, happy, and productive.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Can you help me determine whether a social media marketing campaign will help me reach my online marketing goals?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Absolutely. Our team will be happy to consult with you to determine which social media strategies and platforms will best achieve results for your company.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSeven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      Do I really need to hire an expert for social media?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Many make the mistake of thinking that social media is an easy as posting a few updates. It goes well beyond that and involves elements such as getting the right ratio of self-promotional content, including search friendly elements, leveraging different content types for maximum engagement, and being present and responding to customers and clients. Effective social media management involves a carefully crafted and well thought out plan of attack.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEight">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      What if I haven’t done anything on social media and don’t even have any profiles set up?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>No problem, we can offer set-up assistance including content creation and getting the design elements you need.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingNine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      Do you do any tracking?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>With our social media management services, you will get a monthly report that gives detailed information about your social media activity. Careful tracking and monitoring of social media is an important part of the equation. It helps us understand what’s working and when we need to make some tweaks. Social media is constantly changing. What worked last month or last year might not work now.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      What is digital marketing?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Digital marketing is the promotion of a business through digital channels such as a website, search engine optimization, social media, email, or online paid advertising. </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEleven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEleven"
                      aria-expanded="false"
                      aria-controls="flush-collapseEleven"
                    >
                      Why is digital marketing important?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEleven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEleven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Digital marketing provides ways for companies to grow their audience, increase profits, and build a better relationship with past and future customers. While that’s the goal of a traditional marketing campaigns too, digital marketing allows brands to target a more specific or niche audience and is easier to measure results.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwelve">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwelve"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwelve"
                    >
                      How much time should social media marketing take me?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwelve"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwelve"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>This is strongly dependent on your brand and where else you’re marketing. One thing that we will say, though, is brands often underestimate how long it takes to create a successful social media marketing campaign. Our team puts multiple hours in per week to create graphics, write content, engage with other brands, and stay on top of the most recent trends.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThirteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThirteen"
                      aria-expanded="false"
                      aria-controls="flush-collapseThirteen"
                    >
                      How does a website increase sales?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThirteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThirteen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>The primary way a website increases sales is by offering users a convenient way to shop for products and services. Through the use of pop-ups offers and special online discounts, small businesses can encourage consumers to make the switch from window-shopping to buying.</p>
                      <p>With an enhanced incentive to buy, along with clear and concise product descriptions, users are quickly turning to company websites for all their consumer needs.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThirteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThirteen"
                      aria-expanded="false"
                      aria-controls="flush-collapseThirteen"
                    >
                      What is the main purpose of social media marketing?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThirteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThirteen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Social media falls in the brand awareness and consideration stage of the sales funnel. Social media platforms allow businesses to connect with their target audience and slowly but surely nudge them down the funnel with content and links to learn more.</p>
                      <p>By providing relevant information about products and services, engaging with their audience, and providing free trials or specials, businesses are able to nurture leads into buyers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FaqPage;
