import React from 'react'

const HomeSection4 = () => {
  return (
    <div className='bg-theme-blue-section-4 mt-5'>
      <div className='container'>
        <h2 className='text-center text-white'>Our Clients Span a Range of Industries</h2>
        <div className='row mt-3'>
          <div className='col-md-6 col-sm-12 m-bottom-responsive'>
            <div className='d-flex'>
              <div className='law-firm-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Law Firms</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex'>
              <div className='medical-health-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Medical & Healthcare</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6 col-sm-12 m-bottom-responsive'>
            <div className='d-flex'>
              <div className='plumbing-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Plumbing/HVAC</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex'>
              <div className='property-manage-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Property Management</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6 col-sm-12 m-bottom-responsive'>
            <div className='d-flex'>
              <div className='remediation-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Remediation Services</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex'>
              <div className='industry-img'></div>
              <div className='clients-content-style bg-white'>
                <h3 className='m-0'>Many Other Industries</h3>
                <p className='m-0'>Callers reach a live operator and you’ll never miss an opportunity to bring on a new client or take care of an existing one.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection4