import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import ServicesOffered from "../../components/ServicesOffered";
import { SERVICE_SCREEN_SERVICE_LIST } from "../../common/Constant";

const ServicePage = () => {
  return (
    <>
      <Header />
      <div className="pt-5">
        <h1 className="theme_heading_after">Our Services</h1>
        <div className="container mb-5">
          <p className="col-12 col-md-6 mx-auto">
            You deserve a Customer Service Center Partner committed to
            understanding your unique needs, providing prompt service, and
            ensuring seamless communication. At our center, we invest time in
            comprehensively learning about your business operations, enabling us
            to handle your calls and inquiries with the utmost care and
            attention. Your customers are our priority, and we aim to support
            them just as diligently as you do."
          </p>
        </div>
        <ServicesOffered serviceData={SERVICE_SCREEN_SERVICE_LIST} />
        {/* <div className="container mb-5">
          <div className="row">
            <div className="col-md-3">
              <Link to="/service-detail/inBound-service">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">InBound service</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/outBound-service">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">OutBound service</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/email-support">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Email Support</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/customer-service">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">24/7 Customer service</p>
              </div>
                </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="container mb-5">
          <div className="row">
            <div className="col-md-3">
              <Link to="/service-detail/phone-services-expert">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Phone Services Expert</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/revamp-social-presence">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Revamp Social Presence</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/update-business-website">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Update Business Website</p>
              </div>
                </Link>
            </div>
            <div className="col-md-3">
              <Link to="/service-detail/manage-online-sales">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Manage Online Sales </p>
              </div>
                </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="container mb-5">
          <div className="row">
            <div className="col-md-3">
              <Link to="/service-detail/mobile-app-development">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Mobile App development</p>
              </div>
                </Link>
            </div>

            <div className="col-md-3">
              <Link to="/service-detail/web-app-development">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Web App development</p>
              </div>
                </Link>
            </div>

            <div className="col-md-3">
              <Link to="/service-detail/backend-development">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">BackEnd development</p>
              </div>
                </Link>
            </div>


          </div>
        </div> */}
        {/* <div className="container mb-5 ">
          <div className="row align-center">
            <div className="col-md-3">
              <div className="card background_color p-3">
                <p className="fs-2 mb-0">Phone services</p>
              </div>
            </div>

          </div>
        </div> */}

        <div className="bg-light py-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 text-start">
                <h1 className="theme_heading_regular text-start">FAQ</h1>
                <p>
                  Don’t see the topic you’re looking for? Check out our blog or
                  contact our team. We’re here to help.
                </p>
                <a href="/faq" className="btn btn-primary theme_btn btn-lg">
                  Explore More
                </a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is an inbound call centre?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>
                          An inbound call center receives incoming calls from
                          customers. Support teams typically monitor inbound
                          centers since the calls tend to come from existing
                          customers with issues or questions. An outbound call
                          center, on the other hand, makes outgoing calls to
                          shoppers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        What does inbound calls mean?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>
                          Inbound calling occurs when customers or potential
                          customers initiate contact with a business or
                          organization. Inbound call center agents provide
                          reactive customer support, meaning when someone calls,
                          they react.
                        </p>
                        {/* <p>The Incoterms® Rules are protected by copyright owned by ICC.  Further information on the Incoterms® Rules may be obtained from the ICC website iccwbo.org.</p> */}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        How many types of inbound calls are there?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>There are two main types of inbound calls:</p>
                        <p>
                          These calls are usually initiated by potential
                          customers who have a question about a product or
                          service. Agents handling this type of call are
                          answering customer questions and trying to prompt a
                          sale.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
