import React from "react";
import lawFirms from "../../../assets/imgs/law-firms.jpg";
import contactBg from "../../../assets/imgs/contact-bg.png";
import contact from "../../../assets/imgs/contact-2.jpg";

const HomeSection8 = () => {
  return (
    <div className="contact">
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={lawFirms} className="contact-img" />
        </div>
        <div className="col-md-4 col-sm-12 position-relative">
          <img src={contactBg} className="contact-bg-img" />
          <div className="blog-question text-center p-4">
            <h2 className="mb-3">
              Have Questions? <br />
              We have answers!
            </h2>
            <p>
              Contact Ama Global Network today so we can help your business with
              its answering service or call center needs.
            </p>
            <div className="contact-us-today text-center">
              <button className="button-contact-us">CONTACT US TODAY</button>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <img src={contact} className="contact-img" />
        </div>
      </div>
    </div>
  );
};

export default HomeSection8;
