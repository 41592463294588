import React from "react";
import extra from "../../../assets/imgs/extra-space-storage.png";
import valassis from "../../../assets/imgs/valassis.png";
import constellation from "../../../assets/imgs/constellation.png";
import global from "../../../assets/imgs/global-medical.png";
import thermo from "../../../assets/imgs/thermo-scientific.png";
import charles from "../../../assets/imgs/charles-river.png";

const HomeSection6 = () => {
  return (
    <div className="logos">
      <div className="container">
        <h2>
          Clients Who Rely on Ama Global Network for Their Answering Service &
          Call Center Needs
        </h2>
        <div className="logo-repeater">
          <ul className="repeater">
            <li>
              <img src={extra} className="logos-img" />
            </li>
            <li>
              <img src={valassis} className="logos-img" />
            </li>
            <li>
              <img src={constellation} className="logos-img" />
            </li>
            <li>
              <img src={global} className="logos-img" />
            </li>
            <li>
              <img src={thermo} className="logos-img" />
            </li>
            <li>
              <img src={charles} className="logos-img" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeSection6;
