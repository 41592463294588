import React from 'react'
import GettyI from '../../../assets/imgs/Gettyl.jpg.webp'
import banner2 from '../../../assets/imgs/banner-2.jpg.webp'
import Getty3 from '../../../assets/imgs/Gettyl3.webp'

const HomeSection7 = () => {
  return (
    <div className='hp-blogs'>
      <div className='container'>
        <div className='inner-content'>
          <h2>Call Center & Answering Service Tips & Tricks</h2>
          <div className='blogs'>
            <div className='row'>
              <div className='col-md-4 col-sm-12 mt-in-responsive'>
                <div className='position-relative'>
                  <div className='blog-img'>
                    <img src={GettyI} className='blog-img-style' />
                  </div>
                  <div className='blog-inner-content'>
                    <h3 className='mb-3'>How to Improve Call Center Productivity</h3>
                    <a className='text-decoration-none'>Read More</a>
                  </div>
                  <div class="blog-date">
                    <span>Jul 12</span>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12 mt-in-responsive'>
                <div className='position-relative'>
                  <div className='blog-img'>
                    <img src={banner2} className='blog-img-style' />
                  </div>
                  <div className='blog-inner-content'>
                    <h3 className='mb-3'>Essential Skills for Call Center Agents</h3>
                    <a className='text-decoration-none'>Read More</a>
                  </div>
                  <div class="blog-date">
                    <span>Jul 12</span>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12 mt-in-responsive'>
                <div className='position-relative'>
                  <div className='blog-img'>
                    <img src={Getty3} className='blog-img-style' />
                  </div>
                  <div className='blog-inner-content'>
                    <h3 className='mb-3'>5 Strategies to Improve Customer Experience</h3>
                    <a className='text-decoration-none'>Read More</a>
                  </div>
                  <div class="blog-date">
                    <span>Jul 12</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center blog-link">
            <a className="button-blog text-decoration-none mb-4" href="#">
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection7