import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const About = () => {
  return (
    <>
      <Header />
      <section className="about-section">
        <div className="container text-start">
          <div className="row py-5">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About Us</span>
                  <h2>We provide truly prominent solutions for your success</h2>
                </div>
                <div className="text">
                To be a market leader by providing quality products and superior service to our customers and clients, while learning from their feedback to set even higher standards for our products and services.
To be a company that continuously enhances its superior technological competence to provide innovative solutions to cater to customer’s needs and client’s requirement.
To be a company that attracts and retains outstanding people by creating a culture that fosters openness and innovation, promotes individual growth, and rewards initiative and performance.
To be a company which combines its people, technology, management systems, and market opportunities to achieve profitable growth while providing fair returns to its shareholders.
To be a company that endeavors to set the highest standards in corporate ethics in serving the society

                </div>
                <h6 className="color-black">Why Choose Us: </h6>
                <div className="text">
                AM Global Network is a trustworthy and most precise Taxi and Limo Dispatch service provider. Fulfilling the requirements of clients and providing them best solution is our priority and we work in such a way that it will surely bring the best growth results to your business. We provide a friendly environment to your clients over the phone and outclass customer service platform to enhance your business. We promise to deliver quality services to you and your clients, also ensures you the maximum level of your customer satisfaction.
                </div>
                <h6 className="color-black">Dedicated Team: </h6>
                <div className="text">
                  Our staff members are knowledgeable, educated, and dedicated
                  to guiding you to the Digital solutions best for your
                  company. We have trained industry professionals,
                  that we also equip with the latest technology and tools they
                  need to provide you with the best possible customer
                  experience.
                </div>
                <div className="btn-box">
                  <a href="/contact" className="theme-btn btn-style-one">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <div className="author-desc">
                  <h2>Am Global</h2>
                  <span>Network</span>
                </div>
                <figure className="image-1">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                    <img
                      title="I'm Admin"
                      src="https://i.ibb.co/QP6Nmpf/image-1-about.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="sec-title mb-4">
            <h2>Our Mission</h2>
          </div>
          <div className="text">
          AM Global Network seeks to partner with like-minded companies to continually meet the needs of our customers, the needs of our employees, and the demands of a constantly-shifting marketplace. We strive to cultivate a work environment made up of the best team in the market and employees who take pride in their company, their team, and our collective goal achievements. We build lasting relationships with our customers to add value not only in the business but also to your brand identity.
          </div>
          <div className="sec-title mb-4">
            <h2>MISSION STATEMENT</h2>
          </div>
          <div className="text">
          Our Mission Is To Make Our Customers Buying Journey Super Easy!
          </div>
          <div className="sec-title mb-4">
            <h2>WHAT WE DO</h2>
          </div>
          <div className="text">
          AM Global Network is a Company that offers impeccable Call Center & Marketing, Lead Generation and I.T services to its valued clients for more than a decade, who are seeking assistance in handling different I.T related services and business process outsourcing
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
